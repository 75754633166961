.Home {
  background-color: rgba(22, 67, 80, 0.5);
  z-index: 0;
  position: relative;
  height: 100vh;
  width: 100vw;
}

.Home_particles {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.Home .content {
  height: 100vh;
  width: 100vw;
}

.content.exiting {
  opacity: 0;
  zoom: 0;
  transition: all 0.4s;
}

.Home .button {
  color: white;
  display: inline-flex;
  align-items: center;
  margin: 5px 20px;
  cursor: pointer;
}
