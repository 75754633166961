* {
  box-sizing: border-box;
}

.NightModeToggle {
  --black: #121212;
  --white: #f5f5f5;
  --transition: 0.3s ease;
}

.hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.toggle_wrapper {
  width: 75px;
  display: block;
}

.toggle {
  height: 35px;
  width: 75px;
  background: var(--color);
  border-radius: 40px;
  padding: 5px;
  position: relative;
  cursor: pointer;
}

.toggle::before {
  content: "";
  display: block;
  height: 25px;
  width: 25px;
  border-radius: 30px;
  background: var(--background);
  position: absolute;
  z-index: 2;
  transform: translate(0);
  transition: transform var(--transition);
}

.toggle.enabled::before {
  transform: translateX(40px);
}

.toggle input {
  display: none;
}

.toggle .icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 2px;
  color: var(--background);
}

.toggle .icons svg {
  fill: var(--background);
  height: 20px;
  width: 20px;
  z-index: 0;
}
