.Resume {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.15);

  backdrop-filter: blur(5px);
}

.vl {
  border-left: 6px solid white;
  height: 500px;
  display: fixed;
  z-index: -1;
}
