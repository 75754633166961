@keyframes slideInFromTop {
  0% {
    transform: translateY(-200%);
    opacity: 1;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes NavigationFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-200%);
  }
  100% {
    opacity: 1;
    transform: translateY(-0%);
  }
}

.Navigation {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  padding: 20px 50px 20px 50px;
  background-color: var(--background);
  color: black;
  opacity: 0;
  animation: NavigationFadeIn 1s ease 0s forwards;
}

.Navigation .nav_icon {
  opacity: 0;
  animation: slideInFromTop 1s ease 0.5s forwards;
}
.Navigation .nav_link1 {
  opacity: 0;
  color: var(--color);
  animation: slideInFromTop 1s ease 0.7s forwards;
}
.Navigation .nav_link2 {
  opacity: 0;
  color: var(--color);
  animation: slideInFromTop 1s ease 0.9s forwards;
}
.Navigation .nav_link3 {
  opacity: 0;
  color: var(--color);
  animation: slideInFromTop 1s ease 1.1s forwards;
}

.Navigation .nav_toggle {
  opacity: 0;
  animation: slideInFromTop 1s ease 1.5s forwards;
}

.MobileNavigation {
  width: 250px;
  background-color: var(--background);
  color: var(--color);
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
}

.MobileNavigation .nav_container {
  display: inline-block;
  cursor: pointer;
}

.Navigation .bar1,
.Navigation .bar2,
.Navigation .bar3 {
  width: 30px;
  height: 3px;
  background-color: var(--color);
  margin: 6px 0;
}

.MobileNavigation .nav-link {
  width: 100%;
  color: var(--color);
  height: 50px;
}
