@keyframes transformContentArea {
  0% {
    z-index: 9999;
    transform: translateZ(9999);
    transform: scale(2, 2);
  }
  100% {
    z-index: 0;
    transform: translateZ(0);
    transform: scale(1, 1);
  }
}

@keyframes transformContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.night {
  --color: #f5f5f5;
  --background: #121212;
}

.day {
  --color: #121212;
  --background: #f5f5f5;
}

.App {
  width: 100%;
  height: 100%;
  background-color: var(--background);
}

.App_particles {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  /* z-index: -1; */
}

.App_content_area {
  margin: 90px 50px 0px 50px;
  background-color: #16435080;
  border-radius: 0.5rem;
  overflow: hidden;
  z-index: 0;
  position: relative;
  animation: 1s ease-out 0s 1 transformContentArea;
}

.App_filter {
  background-color: rgba(255, 255, 255, 0.15);
  /* color: #889ba2; */
  /* backdrop-filter: blur(0.05rem); */
}

.App_left {
  position: fixed;
  left: 0;
  width: 50px;
  height: 100%;
  background-color: var(--background);
  opacity: 0;
  animation: transformContent 1s ease-out 0s forwards;
}

.App_right {
  position: fixed;
  right: 0;
  width: 50px;
  height: 100%;
  background-color: var(--background);
  opacity: 0;
  animation: transformContent 1s ease-out 0s forwards;
}

.App_bottom {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: var(--background);
  opacity: 0;
  animation: transformContent 1s ease-out 0s forwards;
}

.App_content {
  height: 80vh;
  overflow: auto;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  opacity: 0;
  animation: transformContent 1s ease-out 1.5s forwards;
}
/* SCROLLBAR */
.App_content::-webkit-scrollbar {
  width: 10px;
  padding-right: 50px;
}

/* Track */
.App_content::-webkit-scrollbar-track {
  background: rgb(250, 250, 250);
  border: 4px solid transparent;
  background-clip: content-box; /* THIS IS IMPORTANT */
  margin: 10px 20px 400px;
}

/* Handle */
.App_content::-webkit-scrollbar-thumb {
  border-radius: 20px;
  width: 2px;
  background: rgb(250, 250, 250);
  border: 1px solid rgb(250, 250, 250);
}

.App_night_toggle {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
}

@media screen and (max-width: 700px) {
  .App_content_area {
    margin: 90px 10px 0px 10px;
  }
}
